<template>
  <div id="inner">
    <div class="row">
      <div class="col-12 col-sm-6 text-center">
        <img class="logo" src="../assets/img/logo_kvnh.svg"/>
      </div>
      <div class="col-12 col-sm-6 text-center">
        <img class="logo" src="../assets/img/logo_woonzorg.png"/>
      </div>
    </div>
    <br/>
    <div class="row" v-if="$store.state.authentication.user.transferred == 1">
      <div class="col-12 col-sm-10 offset-sm-1">
        <h1>
          {{ $t('home_done.title') }}
        </h1>
        <div v-html="$t('home_done.intro')"></div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-12 text-center" v-if="this.$store.state.authentication.user.checkBirthdate == true">
        <div class="row">
          <div class="col-12">
            <h1>
              {{ $t('home_DOB.title') }}
            </h1>
            <div v-html="$t('home_DOB.text')"></div>
          </div>
        </div>
        <br/>
        <br/>
        <div class="row" v-if="alert.message">
          <div class="col-8 offset-2">
            <div :class="`alert ${alert.type}`">{{ $t(`home_DOB.error.${alert.message}`) }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 offset-md-3">
            <b-form @submit.prevent="handleSubmit">
              <div class="row text-center">
                <label class="col-12">{{ $t('home_DOB.form.title') }}</label>
              </div>
              <div class="row text-center">
                <b-form-select
                        v-model="form.day"
                        required
                        class="col-12 col-md-4"
                >
                  <b-form-select-option value="">
                    Dag
                  </b-form-select-option>
                  <b-form-select-option v-for="n in 31" :key="n" :value="n">
                    {{ n }}
                  </b-form-select-option>
                </b-form-select>
                <b-form-select
                        v-model="form.month"
                        required
                        class="col-12 col-md-4"
                >
                  <b-form-select-option value="">
                    Maand
                  </b-form-select-option>
                  <b-form-select-option v-for="n in 12" :key="n" :value="n">
                    {{ n }}
                  </b-form-select-option>
                </b-form-select>
                <b-form-input
                        type="text"
                        disabled
                        readonly
                        class="col-12 col-md-4"
                        v-model="form.year"
                />
              </div>
              <br/>
              <div class="row text-center">
                <b-button type="submit" class="col-md-6 offset-6" variant="primary">{{ $t('home_DOB.form.buttons.submit') }}</b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-10 offset-sm-1 text-center" v-else>
        <div class="row">
          <div class="col-12">
            <h1>
              {{ $t('home_regular.title') }}
            </h1>
            <div v-html="$t('home_regular.text')"></div>
          </div>
        </div>
        <div class="row">
          <br/>
          <br/>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-sm-1  offset-sm-2">
                <span class="circle-badge">
                  1
                </span>
              </div>
              <div class="col-12 d-block d-sm-none">
                <br/>
              </div>
              <div class="col-12 col-sm-7">
                <button class="btn btn-block btn-primary text-center" v-on:click="$router.push('/check')">
                  {{ $t('home_regular.buttons.haveAccount') }}
                </button>
              </div>
            </div>
            <br/>
            <div class="row">
              <div class="col-12 col-sm-1  offset-sm-2">
                <span class="circle-badge">
                  2
                </span>
              </div>
              <div class="col-12 d-block d-sm-none">
                <br/>
              </div>
              <div class="col-12 col-sm-7">
                <button class="btn btn-block btn-primary text-center" v-on:click="$router.push('/new_account')">
                  {{ $t('home_regular.buttons.noAccount') }}
                </button>
              </div>
            </div>
            <br/>
            <div class="row">
              <div class="col-12 col-sm-1  offset-sm-2">
                <span class="circle-badge">
                  3
                </span>
              </div>
              <div class="col-12 d-block d-sm-none">
                <br/>
              </div>
              <div class="col-12 col-sm-7">
                <button class="btn btn-block btn-primary text-center" v-on:click="$bvModal.show('dialog_refusal');">
                  {{ $t('home_regular.buttons.refuse') }}
                </button>
              </div>
            </div>
            <br/>
            <!--<div class="row">
              <div class="col-12 col-sm-1  offset-sm-2">
                <span class="circle-badge">
                  4
                </span>
              </div>
              <div class="col-12 d-block d-sm-none">
                <br/>
              </div>
              <div class="col-12 col-sm-7">
                <button class="btn btn-block btn-primary text-center" v-on:click="$router.push('/logout')">
                  {{ $t('home_regular.buttons.logout') }}
                </button>
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </div>

    <b-modal
            id="dialog_refusal"
            size="lg"
            v-bind:title="$t('home_regular.dialog.refuse.title')"
            v-bind:ok-title="$t('home_regular.dialog.refuse.buttons.ok')"
            v-bind:cancel-title="$t('home_regular.dialog.refuse.buttons.cancel')"
            @ok="removeData"
    >
      <div v-html="$t('home_regular.dialog.refuse.content')"></div>
    </b-modal>

  </div>
</template>

<script>
import {dataService} from "../_services/data.service";
import {dobService} from "../_services";

export default {
  name: 'Home',
  data: () => {
      return {
        form: {
          day: '',
          month: '',
          year: ''
        }
      }
  },
  computed: {
    alert () {
      return this.$store.state.alert
    }
  },
  methods: {
    async removeData() {
      dataService.remove().then(() => {
        this.$router.push('/refused');
      });
    },
    async handleSubmit() {
      dobService.post(this.form.day, this.form.month).then(() => {
        this.$router.push('/dobUpdated');
      },
      (error) => {
        this.$store.dispatch('alert/error', error, { root: true });
      })
    }
  },
  mounted() {
    this.form.year = this.$store.state.authentication.user.birthyear;
  }
}
</script>


<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
