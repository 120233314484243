<template>
        <div id="inner">
        <div class="row">

            <div class="col-12 col-sm-6 text-center">
                <img class="logo" src="../assets/img/logo_kvnh.svg"/>
            </div>
            <div class="col-12 col-sm-6 text-center">
                <img class="logo" src="../assets/img/logo_woonzorg.png"/>
            </div>
        </div>
        <br/>

        <div v-if="finished">
            <div class="row">
                <div class="col-12 col-md-6 offset-3 text-center" v-if="found == 1">
                    <h1>
                        {{ $t('check.found.title') }}
                    </h1>
                    <div v-html="$t('check.found.intro')">

                    </div>
                </div>
                <div class="col-12 col-md-6 offset-3 text-center" v-else>
                    <h1>
                        {{ $t('check.notfound.title') }}
                    </h1>
                    <div v-html="$t('check.notfound.intro')">

                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <div class="row" v-if="alert.message">
                <div class="col-8 offset-2">
                    <div :class="`alert ${alert.type}`">{{ $t(`check.error.${alert.message}`) }}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-8 offset-md-2">
                    <b-form @submit.prevent="checkAccount">
                        <fieldset>
                            <legend>
                                {{ $t('check.form.legend.data') }}
                            </legend>
                            <b-form-group
                                    v-if="found"
                                    id="email-group"
                                    v-bind:label="$t('check.form.email')"
                                    class="col-11 offset-1"
                            >
                                <b-form-input
                                        type="text"
                                        disabled
                                        readonly
                                        v-bind:value="identifier"
                                />
                            </b-form-group>
                            <b-form-group
                                    v-else
                                    id="identifier-group"
                                    v-bind:label="$t('check.form.identifier')"
                                    class="col-11 offset-1"
                            >
                                <b-form-input
                                        required
                                        type="text"
                                        v-model="identifier"
                                />
                            </b-form-group>
                            <b-form-group
                                    id="dob-group"
                                    v-bind:label="$t('check.form.dob')"
                                    class="col-11 offset-1"
                            >
                                <b-form-input
                                        type="text"
                                        disabled
                                        readonly
                                        v-bind:value="this.$store.state.authentication.user.birthdate"
                                />
                            </b-form-group>
                            <b-button
                                    v-if="!found"
                                    class="float-right"
                                    type="submit"
                                    variant="primary"
                            >
                                {{ $t('check.buttons.search') }}
                            </b-button>
                        </fieldset>
                        <br/>
                        <fieldset>
                            <legend>
                                {{ $t('check.form.legend.result') }}
                            </legend>
                            <div class="col-11 offset-1">
                                <div class="card" v-if="found == 1">
                                    <div class="card-body">
                                        <h1>
                                            {{ $t('check.card.found.title') }}
                                        </h1>
                                        <p v-if="dateChanged == 1" v-html="$t('check.card.found.text.changed', [account, searchdate, name, rule])">
                                        </p>
                                        <p v-else v-html="$t('check.card.found.text.notChanged', [account, searchdate, name, rule])">
                                        </p>
                                    </div>
                                </div>
                                <div class="card" v-else>
                                    <div class="card-body">
                                        <h1>
                                            {{ $t('check.card.notfound.title') }}
                                        </h1>
                                        <p v-html="$t('check.card.notfound.text')">
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <br/>
                        <fieldset>
                            <b-button
                                    v-if="found"
                                    class="float-right"
                                    variant="success"
                                    v-on:click="confirm"
                            >
                                {{ $t('check.buttons.confirm') }}
                            </b-button>
                            <b-button
                                    class="float-right"
                                    variant="primary"
                                    v-on:click="$router.push('/')"
                            >
                                {{ $t('check.buttons.back') }}
                            </b-button>
                            <!--<b-button
                                    v-if="found"
                                    class="float-right"
                                    variant="primary"
                                    v-on:click="otherAccount"
                            >
                                {{ $t('check.buttons.other') }}
                            </b-button>-->
                        </fieldset>
                        <br/>
                        <br/>
                        <br/>
                    </b-form>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="row">
                <div class="col-12">
                    <h1 class="text-center">
                        {{ $t('check.loading') }}
                    </h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {checkService} from "../_services/check.service";

    export default {
        name: 'Check',
        data: () => {
            return {
                found: 0,
                account: '',
                id: '',
                finished: 0,
                name: '',
                email: '',
                searchdate: '',
                rule: '',
                identifier: '',
                dateChanged: ''
            }
        },
        computed: {
            alert () {
                return this.$store.state.alert
            }
        },
        mounted() {
            this.identifier = this.$store.state.authentication.user.email;
            this.checkAccount();
        },
        methods: {
            checkAccount: function() {
                this.finished = 0;
                checkService.post(this.identifier).then((data)=> {
                    if(data.found == 1) {
                        this.found = 1;
                        this.account = data.data.account;
                        this.id = data.data.id;
                        this.rule = this.$store.state.authentication.user.rule;
                        this.name = data.data.name;
                        this.email = data.data.email;
                        this.searchdate = data.data.searchdate;
                        this.dateChanged = data.data.dateChanged;
                    }
                    console.log(data);
                    this.finished = 1;
                });
            },
            otherAccount: function() {
                this.identifier = '';
                this.account = 0;
                this.found = 0;
            },
            confirm: function() {
                checkService.confirm(this.identifier).then(()=> {
                    this.$router.push('/done_existing');
                }, (error)=> {
                    this.$store.dispatch('alert/error', error, { root: true });
                })
            }
        }
    }
</script>



