<template>
    <div id="inner">
        <div class="row">
            <div class="col-12 col-sm-6 text-center">
                <img class="logo" src="../assets/img/logo_kvnh.svg"/>
            </div>
            <div class="col-12 col-sm-6 text-center">
                <img class="logo" src="../assets/img/logo_woonzorg.png"/>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="col-8 offset-2 text-center">
                <h1>
                    {{ $t('NewAccount.title') }}
                </h1>
                <div v-html="$t('NewAccount.intro')">

                </div>
            </div>
        </div>
        <br/>
        <br/>
        <div class="row" v-if="alert.message">
            <div class="col-8 offset-2">
                <div :class="`alert ${alert.type}`">{{ $t(`NewAccount.error.${alert.message}`) }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-10 offset-sm-1">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">
                            {{$t('NewAccount.card.anw')}}
                        </h5>
                        <div class="card-text row">
                            <div class="col-12 col-sm-4 col-m">
                                <label class="col-form-label">
                                    {{$t('NewAccount.form.identifier')}}
                                </label>
                            </div>
                            <div class="col-12 col-sm-8">
                                <input type="text" class="form-control" v-bind:value="$store.state.authentication.user.identifier" disabled readonly>
                            </div>
                        </div>
                        <br/>
                        <div class="card-text row">
                            <div class="col-12 col-sm-4 col-m">
                                <label class="col-form-label">
                                    {{$t('NewAccount.form.rule')}}
                                </label>
                            </div>
                            <div class="col-12 col-sm-8">
                                <input type="text" class="form-control" v-bind:value="$store.state.authentication.user.rule" disabled readonly>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="col-12 col-sm-10 offset-sm-1">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">
                            {{$t('NewAccount.card.person')}}
                        </h5>
                        <div class="card-text row">
                            <div class="col-12 col-sm-4 col-m">
                                <label class="col-form-label">
                                    {{$t('NewAccount.form.dob')}}
                                </label>
                            </div>
                            <div class="col-12 col-sm-8">
                                <input type="text" class="form-control" v-bind:value="$store.state.authentication.user.birthdate" disabled readonly>
                            </div>
                        </div>
                        <br/>
                        <div class="card-text row">
                            <div class="col-12 col-sm-4 col-m">
                                <label class="col-form-label">
                                    {{$t('NewAccount.form.email')}}
                                </label>
                            </div>
                            <div class="col-12 col-sm-8">
                                <input type="text" class="form-control" v-bind:value="$store.state.authentication.user.email" disabled readonly>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <div class="row" v-if="$store.state.authentication.user.hasPartner === 1">
            <div class="col-12 col-sm-10 offset-sm-1">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">
                            {{$t('NewAccount.card.partner.title')}}
                        </h5>
                        <div class="card-text" v-if="$store.state.authentication.user.partner_transferred">
                            <p v-html="$t('NewAccount.card.partner.text.hasTransferred')"></p>
                        </div>
                        <div class="card-text" v-else>
                            <p v-html="$t('NewAccount.card.partner.text.notTransferred')"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="col-12 col-sm-10 offset-sm-1">
                <b-button
                        class="float-right"
                        variant="success"
                        v-on:click="confirm"
                >
                    {{ $t('NewAccount.buttons.confirm') }}
                </b-button>
                <b-button
                        class="float-right"
                        variant="primary"
                        v-on:click="$router.push('/')"
                >
                    {{ $t('NewAccount.buttons.back') }}
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {newService} from "../_services/new.service";

    export default {
        name: 'NewAccount',
        computed: {
            alert () {
                return this.$store.state.alert
            }
        },
        methods: {
            confirm: function() {
                newService.confirm('placeholder').then(()=> {
                    this.$router.push('/done_new');
                }, (error)=> {
                    this.$store.dispatch('alert/error', error, { root: true });
                })
            }
        }

    }
</script>

