import Vue from 'vue';
import Router from 'vue-router';

import HomePage from '@/components/Home'
import LoginPage from '@/components/Login'
import SSO from "@/components/SSO";
import Refused from "../components/Refused";
import DobUpdated from "../components/DobUpdated";
import Check from "../components/Check";
import DoneExisting from "../components/DoneExisting";
import NewAccount from "../components/NewAccount";
import DoneNew from "../components/DoneNew";

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    routes: [
        { path: '/', component: HomePage },
        { path: '/login', component: LoginPage },
        { path: '/sso', component: SSO },
        { path: '/logout', component: LoginPage },
        { path: '/refused', component: Refused },
        { path: '/dobUpdated', component: DobUpdated },
        { path: '/check', component: Check },
        { path: '/done_existing', component: DoneExisting },
        { path: '/done_new', component: DoneNew },
        { path: '/new_account', component: NewAccount },

        // otherwise redirect to home
        { path: '*', redirect: '/' }
    ]
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/login','/sso','/refused'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');
    if (authRequired && !loggedIn) {
        return next('/login');
    }
    next();
});