const messages = {
    nl: {
        login: {
            title: 'Welkom bij Woonmatch Kop van Noord Holland',
            intro: '<p>Vul hieronder uw gegevens in. Het Persoonsnummer stond vermeld in de brief die u van Woonzorg heeft ontvangen.</p><p>Na het klikken op ‘Verder’ ontvangt u een e-mail waarmee u kunt inloggen bij Welkom bij Woonmatch.</p>',
            form: {
                email: 'E-mail',
                identifier: 'Persoonsnummer',
                dob: 'Geboortedatum'
            },
            button: {
                login: 'Verder'
            },
            error: {
                notfound: 'Geen account gevonden',
                banned: 'Dit IP heeft 10 foute logins geprobeerd in 1 uur en is voor 24 uur geblokkeerd',
                linkExpired: 'Deze link is verlopen, log opnieuw in'
            },
            IE: '<p>U maakt gebruik van Internet Explorer. Deze browser wordt al enige tijd niet meer ondersteund door Windows en is daardoor niet meer veilig. U kunt deze applicatie dan ook niet gebruiken met Internet Explorer. We raden u aan om een van de onderstaande browsers te gebruiken:</p><ul class="list-unstyled"><li>Microsoft Edge</li><li>Mozilla Firefox</li><li>Google Chrome</li></ul>'

        },
        sso: {
            title: 'Er is een e-mail verstuurd',
            intro: '<p>Er is, naar het e-mailadres dat u zojuist heeft ingevuld, een bevestiging gestuurd met een mogelijkheid om direct in te loggen bij “Welkom bij Woonmatch”.</p><p>Open uw mailbox om het overzetten van uw gegevens af te ronden. Als u binnen 5 minuten geen e-mail ontvangen heeft, controleer dan bij ‘Spam’ of ‘Ongewenste e-mail’ of het bericht daar terecht is gekomen.</p>'
        },
        home_DOB: {
            title: 'Vul uw geboortedatum in',
            text: 'U kunt hier uw geboortedatum invullen, het geboortejaar is al ingevuld dat kunt u niet aanpassen.',
            form: {
                buttons: {
                    submit: 'Geboortedatum Aanpassen'
                },
                title: 'Pas uw geboortedatum aan'
            },
            error: {
                dateError: 'Deze datum bestaat niet.',
                alreadyDone: 'U heeft uw geboortedatum al aangepast. Controleer uw e-mail voor een nieuwe link.',
                remoteError: 'Er is iets mis gegaan met de server - probeer het later opnieuw.'
            }
        },
        home_done: {
            title: 'Uw gegevens zijn al verwerkt',
            intro: 'U heeft al eerder uw gegevens laten overzetten. U heeft hierover een e-mail ontvangen met een bevestiging en u kunt nu gewoon inloggen op www.woonmatchkopnh.nl '
        },
        home_regular: {
            title: 'Welkom terug bij Woonmatch!',
            text: '<p>U kunt nu aangeven of u uw gegevens en opgebouwde rechten vanuit Woonzorg vanwege inschrijfduur wilt overzetten naar Woonmatch Kop van Noord Holland.</p><p><strong>Klik op 1 als u al ingeschreven bent in Woonmatch Kop van Noord Holland als woningzoekende.<strong> Dan worden uw inschrijvingen samengevoegd. En krijgt u extra zoekrecht als u reageert op woningen van Woonzorg.</strong></p><p><strong>Klik op 2 als u <u>nog niet</u> ingeschreven bent in Woonmatch Kop van Noord Holland.</strong> Dan krijgt u een nieuwe inschrijving en krijgt u extra zoekrecht als u reageert op woningen van Woonzorg.</p><p><strong>Klik op 3 als u uw gegevens en opgebouwde rechten bij Woonzorg niet wilt overzetten en wilt laten vervallen.</strong>  </p><br/><br/><p>Wilt u uw gegevens overzetten naar Woonmatch Kop van Noord Holland en uw zoekrechten meenemen?</p>',
            buttons: {
                haveAccount: 'Ja, en ik heb al een inschrijving in Woonmatch Kop van Noord Holland',
                noAccount: 'Ja, en ik heb nog geen inschrijving in Woonmatch Kop van Noord Holland',
                refuse: 'Nee, verwijder mijn gegevens',
                logout: 'Later verder gaan'
            },
            dialog: {
                refuse: {
                    title: 'U gaat uw inschrijving bij Woonzorg verwijderen. Weet u dat zeker?',
                    content: 'Als u op “Ja, verwijder mijn gegevens” klikt, verwijdert u <u>definitief</u> uw inschrijving op de wachtlijsten van Woonzorg voor complexen in Kop van Noord Holland. U wordt <u>niet</u> automatisch ingeschreven in Woonmatch Kop van Noord Holland en de inschrijfduur wordt dan <u>niet meegeteld</u> als u reageert op een woning van Woonzorg in Kop van Noord Holland. <u>Deze actie is niet meer terug te draaien!</u> Als dit is wat u wilt, klik dan op “Ja verwijder mijn gegevens”. Klik anders op “Nee ga terug” en kies voor een andere mogelijkheid.',
                    buttons: {
                        ok: 'Ja, verwijder mijn gegevens',
                        cancel: 'Nee, ga terug'
                    }
                }
            }
        },
        refused: {
            title: 'Uw inschrijving bij Woonzorg is verwijderd',
            intro: '<p>Uw inschrijving op de wachtlijsten van Woonzorg voor complexen in Kop van Noord Holland is op verzoek definitief verwijderd. U wordt niet ingeschreven in Woonmatch Kop van Noord Holland.</p><p>Er is naar het e-mailadres dat u zojuist heeft ingevuld een bevestiging van deze verwijdering gestuurd.'
        },
        DobUpdated: {
            title: 'Er is een e-mail verstuurd',
            intro: '<p>Er is, naar het e-mailadres dat u eerder heeft ingevuld, een bevestiging gestuurd met een mogelijkheid om direct in te loggen bij “Welkom bij Woonmatch”.</p><p>Open uw mailbox om het overzetten van uw gegevens af te ronden. Als u binnen 5 minuten geen e-mail ontvangen heeft, controleer dan bij ‘Spam’ of ‘Ongewenste e-mail’ of het bericht daar terecht is gekomen.</p>'
        },
        check: {
            found: {
                title: 'Uw gegevens in Woonmatch Kop van Noord Holland',
                intro: '<p>Bij ‘Uw gegevens’ staan het e-mailadres en de geboortedatum die u ingevuld heeft. Wij hebben in Woonmatch Kop van Noord Holland een inschrijving gevonden met hetzelfde e-mailadres en geboortedatum.</p><p>U vindt die onder ‘Resultaat’ bij ‘Inschrijving gevonden’ het inschrijfnummer, de naam en de zoekrechten in Woonmatch die horen bij deze inschrijving.</p><p>Daaronder ziet u de rechten die u heeft opgebouwd bij Woonzorg en die overgezet gaan worden, inclusief de bonus van 50 jaar extra.</p><p>Klik op ‘Gegevens overzetten’ om de overzetting naar Woonmatch Kop van Noord Holland definitief te maken</p>'
            },
            notfound: {
                title: 'Uw gegevens in Woonmatch Kop van Noord Holland',
                intro: '<p>Bij ‘Uw gegevens’ staan het e-mailadres en de geboortedatum die u ingevuld heeft.</p><p>Er is geen inschrijving gevonden met deze gegevens. Als u zeker weet dat u wel een inschrijving heeft, dan gebruikt u in Woonmatch waarschijnlijk een ander e-mailadres. Vul hieronder bij ‘Uw gegevens dan het e-mailadres in dat u in Woonmatch Kop van Noord Holland gebruikt en klik dan op ‘Zoeken’. Log eventueel eerst in bij Woonmatch Kop van Noord Holland om uw e-mailadres te controleren.</p>'
            },
            loading: 'Bezig met laden...',
            error: {
                alreadyTransferred: 'Uw account is al overgezet.',
                checkDOB: 'Uw geboortedatum moet nog aangepast worden. Log hiervoor opnieuw in.',
                getAccountFirst: 'Haal eerst uw account op',
                remoteError: 'Er is een probleem aan onze kant. Probeer het later nog eens.'
            },
            form: {
                legend: {
                    data: 'Uw gegevens',
                    result: 'Resultaat'
                },
                email: 'E-mail',
                dob: 'Geboortedatum',
                identifier: 'Uw E-mailadres'
            },
            buttons: {
                search: 'Zoeken',
                back: 'Terug',
                confirm: 'Gegevens overzetten',
                other: 'Ander Account'
            },
            card: {
                found: {
                    title: 'Inschrijving gevonden',
                    text: {
                        changed: 'Wij hebben de volgende inschrijving gevonden:<br/>&nbsp;&nbsp;&nbsp;<strong>Inschrijfnummer:</strong> {0}<br/>&nbsp;&nbsp;&nbsp;<strong>Zoekrecht Woonmatch:</strong> {1}<br/>&nbsp;&nbsp;&nbsp;<strong>Naam:</strong> {2}<br/><br/>De volgende rechten worden overgezet:<br/>Zoekdatum voor Woonzorg advertenties inclusief bonus: {3}',
                        notChanged: 'Wij hebben de volgende inschrijving gevonden:<br/>&nbsp;&nbsp;&nbsp;<strong>Inschrijfnummer:</strong> {0}<br/>&nbsp;&nbsp;&nbsp;<strong>Zoekrecht Woonmatch:</strong> {1}<br/>&nbsp;&nbsp;&nbsp;<strong>Naam:</strong> {2}<br/><br/>Uw inschrijving in Woonmatch Kop van Noord Holland heeft al meer zoekrechten dan uw Woonzorg inschrijving. Er worden dus <u>geen</u> extra rechten over gezet naar Woonmatch Kop van Noord Holland. ',
                    }
                },
                notfound: {
                    title: 'Geen inschrijving gevonden',
                    text: 'Vul eventueel een ander e-mailadres in bij Uw gegevens en klik op Zoeken’, of klik op terug om te kiezen voor een nieuwe inschrijving in Woonmatch Kop van Noord Holland'
                }
            }
        },
        NewAccount: {
            title: 'Uw inschrijving in Woonmatch Kop van Noord Holland',
            intro: 'Als u klikt op ‘Inschrijving in Woonmatch aanmaken’ wordt er op uw verzoek een gratis inschrijving aangemaakt in Woonmatch Kop van Noord Holland waarbij onderstaande gegevens worden overgezet. Daardoor worden de rechten die u bij Woonzorg hebt opgebouwd toegevoegd aan uw nieuwe inschrijving <u>inclusief een bonus van 50 jaar extra</u>. Deze worden dan automatisch meegeteld als u reageert op een advertentie van een woning van Woonzorg.',
            card: {
                anw: 'Algemene Gegevens',
                person: 'Uw gegevens',
                partner: {
                    title: 'Uw Partner',
                    text: {
                        hasTransferred: 'Text indien partner al overgezet is',
                        notTransferred: 'Text indien partner nog niet is overgezet'
                    }
                }
            },
            buttons: {
                back: 'Terug',
                confirm: 'Inschrijving in Woonmatch aanmaken'
            },
            form:  {
                rule: 'Zoekrechten voor Woonzorg advertenties inclusief bonus',
                identifier: 'Persoonsnummer',
                dob: 'Geboortedatum',
                email: 'E-mail'
            },
            error: {
                remoteError: 'Er is een probleem aan onze kant. Probeer het later nog eens.',
                accountAlreadyExists: 'Er bestaat al een inschrijving in Woonmatch Kop van Noord Holland met deze combinatie van e-mailadres en geboortedatum. U kunt via Terug en via klikken op de knop "Ja, en Ik heb al inschrijving in Woonmatch Kop van Noord Holland" uw rechten overzetten.',
                alreadyTransferred: 'Uw account is al overgezet.',
                checkDOB: 'Uw geboortedatum moet nog aangepast worden. Log hiervoor opnieuw in.'
            }
        },
        done: {
            existing: {
                title: 'Uw inschrijving in Woonmatch Kop van Noord Holland is bijgewerkt!',
                intro: '<p>Wanneer u <u>langer bij <strong>Woonzorg</strong> ingeschreven stond</u> dan in Woonmatch Kop van Noord Holland, dan is dat aan uw inschrijving in Woonmatch Kop van Noord Holland toegevoegd. Die zoekdatum wordt vanaf nu automatisch meegeteld als u reageert op een advertentie van een woning van Woonzorg.</p><p>Wanneer u <u>al langer in <strong>Woonmatch Kop van Noord Holland</strong> ingeschreven stond</u> dan bij Woonzorg, dan behoudt u uw zoekdatum in Woonmatch Kop van Noord Holland, en dan telt die vanaf nu automatisch mee als u reageert op een advertentie van een woning van Woonzorg.</p><p>Er is een e-mail gestuurd waarin het overzetten van de gegevens bevestigd wordt.</p><p>U kunt dit scherm sluiten. Het overzetten is nu afgerond!</p>'
            },
            new: {
                title: 'Uw inschrijving in Woonmatch Kop van Noord Holland is aangemaakt!',
                intro: '<p>Er is voor u een nieuwe inschrijving in Woonmatch Kop van Noord Holland aangemaakt! De zoekrechten die u bij Woonzorg o.b.v. inschrijfduur hebt opgebouwd zijn nu toegevoegd aan die nieuwe inschrijving, inclusief de bonus van 50 jaar. Deze worden vanaf nu automatisch meegeteld als u reageert op een advertentie van een woning van Woonzorg.</p><p>Er is een e-mail gestuurd met een bevestiging van de inschrijving en een knop om in te loggen, waarna u uw Persoonlijke gegevens verder kunt aanvullen. <strong>Vul die Persoonlijke gegevens zo snel mogelijk en in ieder geval binnen een week in!</strong> Daarmee wordt uw inschrijving definitief.</p><p>U kunt dit scherm sluiten. Het overzetten is nu afgerond!</p>'
            }
        }
    }
}

export default messages