import Vue from 'vue'
import Vuex from 'vuex'
import VueI18n from 'vue-i18n'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { store } from './_store';
import { router } from './_helpers';
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'es6-promise/auto'
import messages from './i18n/nl'
import browserDetect from "vue-browser-detect-plugin";

require ('./assets/css/common.css')

Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueI18n)
Vue.use(browserDetect)

const i18n = new VueI18n({
  locale: 'nl', // set locale
  messages, // set locale messages
})

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})
