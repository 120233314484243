export const userService = {
    login,
    loginToken,
    logout,
    refresh
};

function refresh() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'Token': user.token })
        };
        return fetch(`https://wbw-api.woonmatchkopnh.nl/refresh`, requestOptions)
            .then(handleResponse)
            .then(token => {
                return token;
            });
    } else {
        return false;
    }
}

function login(email, identifier, dob) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, identifier, dob })
    };

    return fetch(`https://wbw-api.woonmatchkopnh.nl/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user.token && user.twofactor !== 1) {
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        });
}

function loginToken(token) {
    console.log(token);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token })
    };

    return fetch(`https://wbw-api.woonmatchkopnh.nl/logintoken`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        });
}


function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 400 || response.status === 404) {
                logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}