<template>
    <div id="inner">
        <div class="row">
            <div class="col-12 col-sm-6 text-center">
                <img class="logo" src="../assets/img/logo_kvnh.svg"/>
            </div>
            <div class="col-12 col-sm-6 text-center">
                <img class="logo" src="../assets/img/logo_woonzorg.png"/>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="col-8 offset-2 text-center">
                <h1>
                    {{ $t('DobUpdated.title') }}
                </h1>
                <div v-html="$t('DobUpdated.intro')">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Refused',
        created () {
            this.$store.dispatch('authentication/logout');
        }
    }
</script>

