<template>
    <div class="row">
        <div class="col-12">
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'navbar',
        data: () => {
            return {
                manager: 0,
                corporation: 0
            }
        },
        mounted() {
            if(this.$store.state.authentication.status.loggedIn) {
                this.manager = this.$store.state.authentication.user.manager;
                this.corporation = this.$store.state.authentication.user.corporation;
            }
        }
    }
</script>