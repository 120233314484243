<template>
    <div id="inner">
        <div v-if="!this.$browserDetect.isIE">
            <div class="row">
                <div class="col-12 col-sm-6 text-center">
                    <img class="logo" src="../assets/img/logo_kvnh.svg"/>
                </div>
                <div class="col-12 col-sm-6 text-center">
                    <img class="logo" src="../assets/img/logo_woonzorg.png"/>
                </div>
            </div>
            <br/>
            <div class="row">
                <div class="col-12 text-center">
                    <h1>
                        {{ $t('login.title') }}
                    </h1>
                    <div v-html="$t('login.intro')">

                    </div>
                </div>
            </div>

            <div class="row" v-if="alert.message">
                <div class="col-8 offset-2">
                    <div :class="`alert ${alert.type}`">{{ $t(`login.error.${alert.message}`) }}</div>
                </div>
            </div>

            <div class="row">
                <div class="col-8 offset-2">
                    <b-form @submit.prevent="handleSubmit">
                        <b-form-group
                            id="email-group"
                            v-bind:label="$t('login.form.email')"
                            label-for="email"
                        >
                            <b-form-input
                                id="email"
                                v-model="email"
                                type="email"
                                required>
                            </b-form-input>
                        </b-form-group>
                        <b-form-group
                                id="identifier-group"
                                v-bind:label="$t('login.form.identifier')"
                                label-for="identifier"
                        >
                            <b-form-input
                                    id="identifier"
                                    v-model="identifier"
                                    type="text"
                                    required>
                            </b-form-input>
                        </b-form-group>
                        <b-form-group
                                id="dob-group"
                                v-bind:label="$t('login.form.dob')"
                                label-for="dob"
                        >
                    <!--        <b-form-input
                                    id="dob"
                                    v-model="dob_day"
                                    required
                                    class="col-6 col-md-2"
                                    type="number"
                                    step="1"
                                    min="1"
                                    max="31"
                                    placeholder="dd"
                            />
                            <b-form-input
                                    id="dob"
                                    v-model="dob_month"
                                    required
                                    class="col-6 col-md-2"
                                    type="number"
                                    step="1"
                                    min="1"
                                    max="12"
                                    placeholder="mm"
                            />
                            <b-form-input
                                    id="dob"
                                    v-model="dob_year"
                                    required
                                    class="col-6 col-md-2"
                                    type="number"
                                    step="-1"
                                    min="1900"
                                    max="2000"
                                    placeholder="jaar"
                            />-->

                            <b-form-select
                                    id="dob"
                                    v-model="dob_day"
                                    required
                                    class="col-6 col-md-2"
                            >
                                <b-form-select-option v-for="n in 31" v-bind:key="n" v-bind:value="n < 10 ? '0' + n : n">
                                    {{n}}
                                </b-form-select-option>
                            </b-form-select>
                            <b-form-select
                                    id="dob"
                                    v-model="dob_month"
                                    required
                                    class="col-6 col-md-5 offset-md-1"
                            >
                                <b-form-select-option v-for="n in months.length" v-bind:key="n" v-bind:value="n < 10 ? '0' + n : n">
                                    {{months[n-1]}}
                                </b-form-select-option>
                            </b-form-select>
                            <b-form-select
                                    id="dob"
                                    v-model="dob_year"
                                    required
                                    class="col-12 col-md-3 offset-md-1"
                            >
                                <b-form-select-option v-for="n in 100" v-bind:key="n" v-bind:value="2000-n">
                                    {{2000-n}}
                                </b-form-select-option>
                            </b-form-select>


                        </b-form-group>
                        <b-button class="float-right" type="submit" variant="primary">{{ $t('login.button.login') }}</b-button>
                    </b-form>
                </div>
            </div>
            <br/>
            <br/>
        </div>
        <div v-else>
            <div class="row">
                <div class="col-12 col-sm-6 text-center">
                    <img class="logo" src="../assets/img/logo_kvnh.svg"/>
                </div>
                <div class="col-12 col-sm-6 text-center">
                    <img class="logo" src="../assets/img/logo_woonzorg.png"/>
                </div>
            </div>
            <br/>
            <div class="row">
                <div class="col-12 text-center">
                    <h1>
                        {{ $t('login.title') }}
                    </h1>
                    <div v-html="$t('login.IE')">

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Login',
        data: () => {
            return {
                email: '',
                identifier: '',
                submitted: false,
                dob_day: '',
                dob_month: '',
                dob_year: '',
                months: ['januari','februari','maart','april','mei','juni','juli','augustus','september','oktober','november','december']
            }
        },
        computed:  {
            loggingIn () {
                return this.$store.state.authentication.status.loggingIn;
            },
            alert () {
                return this.$store.state.alert
            },
            dob () {
               return this.dob_year + '-' + this.dob_month + '-' + this.dob_day;
            }
        },
        created () {
            this.$store.dispatch('authentication/logout');
        },
        methods: {
            checkBundle() {
                if(this.$route.query.bundle !== undefined) {
                    const { dispatch } = this.$store;
                    let token = this.$route.query.bundle;
                    dispatch('authentication/bundle', {token});
                }
            },
            handleSubmit() {
                this.submitted = true;
                const { email, identifier, dob } = this;
                const { dispatch } = this.$store;
                if(email && identifier) {
                    dispatch('authentication/login', { email, identifier, dob});
                }
            }
        },
        async mounted() {

            this.checkBundle();
        }
    }
</script>



