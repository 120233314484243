import { authHeader } from '../_helpers';

export const newService = {
    confirm
};

function confirm(identifier) {
    return authHeader().then(function(token){
        const requestOptions = {
            method: 'POST',
            headers: token,
            body: JSON.stringify({identifier})
        };

        return fetch(`https://wbw-api.woonmatchkopnh.nl/new/confirm`, requestOptions).then(function(response){
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }
                return data;
            });
        });
    })
}